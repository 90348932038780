const $win = $(window);
const $doc = $(document);
const $body = $('body');

let winW = $win.width();
let winH = $win.height();
let winT = $(window).scrollTop();
let docH = $(document).outerHeight();
let $scrollPosition = null;

var isMobile = {
	Android: function() {
		return navigator.userAgent.match(/Android/i);
	},
	BlackBerry: function() {
		return navigator.userAgent.match(/BlackBerry/i);
	},
	iOS: function() {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	},
	Opera: function() {
		return navigator.userAgent.match(/Opera Mini/i);
	},
	Windows: function() {
		return navigator.userAgent.match(/IEMobile/i);
	},
	any: function() {
		return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
	}
};

// Your cod e goes here..dfghjk.
// jQuery.ready is no longer needed

navigationDesktopHovers();
removeNavOnDocClick();

// Add class for mobile devices
if (isMobile.any()) {
	$body.addClass('mobile-view');
}

// Scroll To Section
$('[data-scroll-nav] a').on('click', function(e){
	const targetSection = $($(this).attr('href'));

	if (targetSection.length) {
		e.preventDefault();

		$('html, body').animate({
			scrollTop: targetSection.offset().top - 70
		}, 1000)
	}
});

$('#field-select-anchor').on('change', function(e){
	const targetSection = $($(this).val());

	if (targetSection.length) {
		e.preventDefault();

		$('html, body').animate({
			scrollTop: targetSection.offset().top - 70
		}, 1000)
	}
});

// Check for List lenghts
$('.list-benefits').each(function(index, el) {
	if ($(this).find('li').length > 6) {
		$(this).addClass('has-hidden-items')
	}
});

$('.list-benefits .btn-more').on('click', function(e){
	e.preventDefault();

	const activeText = $(this).data('active');
	const inactiveText = $(this).data('inactive');

	$(this)
		.toggleClass('expanded')
		.closest('.list-benefits')
		.toggleClass('expanded');

	if ($(this).is('.expanded')) {
		$(this).html(activeText)
	} else {
		$(this).html(inactiveText)
	}
});

// Check for Contract length
$('.section-contact .section__group').each(function(index, el) {
	if ($win.width() > 767) {
		return;
	}

	$win.on('load resize', () => {
		if ($(this).outerHeight() > 340 ) {
			$(this).addClass('has-hidden-items')
		}
	})
});

// Expand Contacts
$('.section-contact .btn-more').on('click', function(e){
	e.preventDefault();

	const activeText = $(this).data('active');
	const inactiveText = $(this).data('inactive');

	$(this)
		.toggleClass('expanded')
		.closest('.section__group')
		.toggleClass('has-hidden-items');

	if ($(this).is('.expanded')) {
		$(this).html(activeText)
	} else {
		$(this).html(inactiveText)
	}
});

// Mobile Nav trigger
$('.nav-trigger').on('click', function(e){
	e.preventDefault();
	e.stopPropagation();

	$body.toggleClass('has-menu-opened');
});

$win.on('load', function(){

})
.on('load resize', function(){
	winW = $win.width()
	winH = $win.height();
	winT = $win.scrollTop();
	docH = $(document).outerHeight();

	if ($win.width() > 767) {
		$(".nav > ul > li > ul").equalizeHeight();
	}
})
.on('scroll', () => {
	winT = $win.scrollTop();
	$body.toggleClass('has-fixed-header', winT > 0);
})

function navigationDesktopHovers() {
	$('.nav a').on('mouseenter click',
	function(e){
		if ($win.width() < 768) {
			return
		}

		if (isMobile.any() && !$(this).hasClass('hover') && e.type === 'click') {
			console.log('here');
			e.preventDefault();
			$(this).addClass('hover');
		}

		$(this)
			.parent()
			.addClass('hover')
			.siblings()
			.removeClass('hover');

		$body.addClass('has-nav-expand');
	});



	$('.nav').hover(
	function(e){},
	function (e) {
		if ($win.width() < 768) {
			return
		}
		$body.removeClass('has-nav-expand')
		$('.nav .hover').removeClass('hover');
	});

	$('.nav-close').on('click', function(e){
		$body.removeClass('has-nav-expand')
		$('.nav .hover').removeClass('hover');
	});
}

function removeNavOnDocClick() {
	$doc.on('click', function(){
		$body.removeClass('has-menu-opened')
	})

	$('.header .header__content').on('click', function(e){
		e.stopPropagation();
	})
}

 $.fn.equalizeHeight = function(){
	var maxHeight = 0, itemHeight;

	this.css('height', '');

	for (var i = 0; i < this.length; i++){
		itemHeight = $(this[i]).height();
		if (maxHeight < itemHeight) {
			maxHeight = itemHeight;
		}
	}

	return this.height(maxHeight);
};